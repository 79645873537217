import React, {useEffect, useState} from "react";
import {BrowserRouter as Router} from 'react-router-dom';
import Head from "./components/utils/Head";
import Page from "./components/Router";
import logo_heb from './img/logo_aurora.png'
import logo_heh from './img/logo_vhs.png'
import logo_het from './img/logo_vt.png'
import logo_hea from './img/logo_hea.png'
import tongiao from './components/utils/FileData/tongiao.json'
import dantoc from './components/utils/FileData/dantoc.json'
import hea_ctdt from './components/utils/FileData/hea_ctdt.json'
import hea_nganh from './components/utils/FileData/hea_nganh.json'
import heb_ctdt from './components/utils/FileData/heb_ctdt.json'
import heb_nganh from './components/utils/FileData/heb_nganh.json'
import heh_ctdt from './components/utils/FileData/heh_ctdt.json'
import heh_nganh from './components/utils/FileData/heh_nganh.json'
import het_ctdt from './components/utils/FileData/het_ctdt.json'
import het_nganh from './components/utils/FileData/het_nganh.json'
import tinh from './components/utils/FileData/tinh.json'
import phuong_xa from './components/utils/FileData/phuong-xa.json'
import quan_huyen from './components/utils/FileData/quan_huyen.json'

function App() {
  // const school  = {        //HEB
  //   truong: 'HEB',
  //   maTruong : 'CDD0225',
  //   file_ctdt : '/heb_ctdt.json',
  //   file_nganh : '/heb_nganh.json',
  //   color : '#1360ab',
  //   logo: logo_heb,
  //   title: 'Trường Cao đẳng Bình Minh Sài Gòn',
  //   tongiao: tongiao,
  //   dantoc: dantoc,
  //   tinh: tinh,
  //   phuong_xa: phuong_xa,
  //   quan_huyen: quan_huyen,
  //   link: 'https://aurora.edu.vn/',
  //   link_gioithieu: 'https://aurora.edu.vn/gioi-thieu/'
  // }

  const school  = {        //HEH
    truong: 'HEH',
    maTruong : 'TCD0236',
    file_ctdt : '/heh_ctdt.json',
    file_nganh : '/heh_nganh.json',
    color : '#003399',
    logo: logo_heh,
    title: 'Trường Trung cấp Y Dược Vạn Hạnh',
    tongiao: tongiao,
    dantoc: dantoc,
    tinh: tinh,
    phuong_xa: phuong_xa,
    quan_huyen: quan_huyen,
    link: 'https://vhs.edu.vn/',
    link_gioithieu: 'https://vhs.edu.vn/ve-y-duoc-van-hanh/'
  }

  // const school  = {       // HET
  //   truong: 'HET',
  //   maTruong : 'TCD0237',
  //   file_ctdt : '/het_ctdt.json',
  //   file_nganh : '/het_nganh.json',
  //   color : '#27BCFA',
  //   logo: logo_het,
  //   title: 'Trường trung cấp Vạn Tường',
  //   tongiao: tongiao,
  //   dantoc: dantoc,
  //   tinh: tinh,
  //   phuong_xa: phuong_xa,
  //   quan_huyen: quan_huyen,
  //   link: 'https://vt.edu.vn/',
  //   link_gioithieu: 'https://vt.edu.vn/gioi-thieu/lich-su-phat-trien/'
  // }

  // const school  = {       // HEA
  //   truong: 'HEA',
  //   maTruong : 'CDD3306',
  //   file_ctdt : '/hea_ctdt.json',
  //   file_nganh : '/hea_nganh.json',
  //   color : '#57b53c',
  //   logo: logo_hea,
  //   title: 'Trường Cao đẳng Âu Lạc - Huế',
  //   tongiao: tongiao,
  //   dantoc: dantoc,
  //   tinh: tinh,
  //   phuong_xa: phuong_xa,
  //   quan_huyen: quan_huyen,
  //   link: 'https://aulachue.edu.vn/',
  //   link_gioithieu: 'https://aulachue.edu.vn/gioi-thieu/hanh-trinh-va-phat-trien/'
  // }


  // độ dài , rộng màn hình
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
      const handleResize = () => {
          setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight
          });
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
  },[]);

  useEffect(() => {
      setIsMobile(windowSize.width < 768)
  }, [windowSize]);

  return (
    <Router>
      <div className="App">
          <Page isMobile={isMobile} school={school}/>
          <Head school={school}/>
      </div>
    </Router>
  );
}

export default App;
