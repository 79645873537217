import React, {useState, useEffect, useRef} from 'react'
import Grid from '@mui/material/Grid';
import { FaChevronDown, FaFileAlt, FaChevronRight, FaLeaf } from "react-icons/fa";
import { FiPlus, FiUpload } from "react-icons/fi";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Header from './Header';
import Popup from '../img/popup.png'
import axios from 'axios';

const dataDefault = {
    hodem:'',
    ten:'',
    gioitinh:'',
    dantoc:'',
    tongiao:'',
    ngaysinh:'',
    noisinh:'',
    dienthoaithisinh:'',
    email:'',
    cccd:'',
    ngaycap:'',
    noicap:'',
    dienthoaiphuhuynh:'',

    tinh_tp_thuongtru:'',
    huyen_quan_thuongtru:'',
    xa_phuong_thuongtru:'',
    diachi_thuongtru:'',

    tinh_tp_hocvan:'',
    huyen_quan_hocvan:'',
    tentruongtrunghoc_hocvan:'',
    khac_hocvan:'',

    chuongtrinh_nganh_1:'',
    nganh_1:'',
    nganh_2:'',
    totnghiep:''
}

export default function Main({isMobile, school}) {
    const [data, setData] = useState(dataDefault)

    const [themNganh, setThemNganh] = useState(false)
    const [checkLienLac, setCheckLienLac] = useState(false)

    const [fileImageName_cccd, setFileImageName_cccd] = useState('')
    const [fileImageName_hocba, setFileImageName_hocba] = useState('')
    const [fileImageName_bangcap, setFileImageName_bangcap] = useState('')

    const [image_cccd, setImage_cccd] = useState('')
    const [image_hocba, setImage_hocba] = useState('')
    const [image_bangcap, setImage_bangcap] = useState('')

    const [tinh_lienhe, setTinh] = useState('')
    const [huyen_lienhe, setHuyen] = useState('')
    const [xa_lienhe, setXa] = useState('')
    const [diachi_lienhe, setDiachi] = useState('')

    const [HuyenPublic, setHuyenPublic] = useState([])
    const [XaPublic, setXaPublic] = useState([])
    const [HuyenPublic2, setHuyenPublic2] = useState([])
    const [XaPublic2, setXaPublic2] = useState([])
    const [HuyenPublic3, setHuyenPublic3] = useState([])

    const [chuongTrinhDaoTao, setChuongTrinhDaoTao] = useState([])

    const [nganh_public_1, setNganh_public_1] = useState([])
    const [nganh_public_2, setNganh_public_2] = useState([])

    const [ShowForm_thongtin, setShowForm_thongtin] = useState(true)
    const [ShowForm_lienlac, setShowForm_lienlac] = useState(false)
    const [ShowForm_hocvan, setShowForm_hocvan] = useState(false)
    const [ShowForm_dangky, setShowForm_dangky] = useState(false)
    const [ShowForm_yeucau, setShowForm_yeucau] = useState(false)

    const [checkPopup, setCheckpopup] = useState(false)

    const [hodem, setHodem] = useState(false);
    const [ten, setTen] = useState(false);
    const [gioitinh, setGioitinh] = useState(false);
    const [dantoc, setDantoc] = useState(false);
    const [tongiao, setTongiao] = useState(false);
    const [ngaysinh, setNgaysinh] = useState(false);
    const [noisinh, setNoisinh] = useState(false);
    const [dienthoaithisinh, setDienthoaithisinh] = useState(false);
    const [email, setEmail] = useState(false);
    const [cccd, setCccd] = useState(false);
    const [ngaycap, setNgaycap] = useState(false);
    const [noicap, setNoicap] = useState(false);
    const [dienthoaiphuhuynh, setDienthoaiphuhuynh] = useState(false);
    const [tinh_tp_thuongtru, setTinhTpThuongtru] = useState(false);
    const [huyen_quan_thuongtru, setHuyenQuanThuongtru] = useState(false);
    const [xa_phuong_thuongtru, setXaPhuongThuongtru] = useState(false);
    const [diachi_thuongtru, setDiachiThuongtru] = useState(false);
    const [tinh_tp_hocvan, setTinhTpHocvan] = useState(false);
    const [tinh_huyen_hocvan, setHuyenTpHocvan] = useState(false);
    const [tentruongtrunghoc_hocvan, setTentruongtrunghocHocvan] = useState(false);
    const [chuongtrinh_nganh_1, setChuongtrinhNganh1] = useState(false);
    const [nganh_1, setNganh1] = useState(false);
    const [totnghiep, setTotnghiep] = useState(false);
    const [tinh_lienhe2, setTinhLienhe2] = useState(false);
    const [huyen_lienhe2, setHuyenLienhe2] = useState(false);
    const [xa_lienhe2, setXaLienhe2] = useState(false);
    const [diachi_lienhe2, setDiachiLienhe2] = useState(false);
  
    const thong_tin_thi_sinh = useRef(null);
    const thong_tin_lien_lac = useRef(null);
    const thong_tin_hoc_van = useRef(null);
    const thong_tin_dang_ky = useRef(null);

    const handleChange = (e)=>{
        const {name, value} = e.target
        setData((prevData) => ({ ...prevData, [name]: value }))
    }

    const handleAutocompleteChange = (name) => (event, newValue) => {
        handleChange({ target: { name, value: newValue } })
    }

    const onFileChangeImage_cccd = (event) => {
        const file = event.target.files[0]

        if (!file) {
            return; // Không có tệp được chọn
        }

        const maxSizeInBytes = 100 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 100MB.')
            return
        }

        if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf')) {
        //   const imageURL = URL.createObjectURL(file)
          setFileImageName_cccd(file.name)
          setImage_cccd(file)
        } else {
          alert('Hình ảnh không hợp lệ.')
        }
    }

    const onFileChangeImage_hocba = (event) =>{
        const file = event.target.files[0]

        if (!file) {
            return; // Không có tệp được chọn
        }

        const maxSizeInBytes = 100 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 100MB.')
            return
        }

        if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf')) {
        //   const imageURL = URL.createObjectURL(file)
          setFileImageName_hocba(file.name)
          setImage_hocba(file)
        } else {
          alert('Hình ảnh không hợp lệ.')
        }
    }

    const onFileChangeImage_bangcap = (event) =>{
        const file = event.target.files[0]

        if (!file) {
            return; // Không có tệp được chọn
        }

        const maxSizeInBytes = 100 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            alert('Kích thước file phải dưới 100MB.')
            return
        }

        if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf')) {
        //   const imageURL = URL.createObjectURL(file)
          setFileImageName_bangcap(file.name)
          setImage_bangcap(file)
        } else {
          alert('Hình ảnh không hợp lệ.')
        }
    }

    useEffect(()=>{
        const getData = ()=>{
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    if(key==='tinh_tp_thuongtru'){
                        checkLienLac ? setTinh(data[key]) : setTinh('') 
                    }
                    else if(key==='huyen_quan_thuongtru'){
                        checkLienLac ? setHuyen(data[key]) : setHuyen('')
                    }
                    else if(key==='xa_phuong_thuongtru'){
                        checkLienLac ? setXa(data[key]) : setXa('')
                    }
                    else if(key==='diachi_thuongtru'){
                        checkLienLac ? setDiachi(data[key]) : setDiachi('')
                    }
                }
              }
        }
        getData()
    },[checkLienLac])

    useEffect(()=>{
        const handleAutocomplete = ()=>{
            let arr=[], arr2=[], arr3=[], arr4=[], nganh_1=[], nganh_2=[], arr5=[]
            for (let key in data) {
                if (data.hasOwnProperty(key)){
                    if(key==='tinh_tp_thuongtru'){
                        // setId_tinh(data[key]?data[key].split(' - ')[0]:'')
                        school.quan_huyen.forEach(el=>{
                            if(data[key]){
                                if(parseInt(el.maTP)===parseInt(data[key].split(' - ')[0])){
                                    arr.push(el)
                                }
                            }

                            if(tinh_lienhe){
                                if(parseInt(el.maTP)===parseInt(tinh_lienhe.split(' - ')[0])){
                                    arr3.push(el)
                                }
                            }

                            if(data.tinh_tp_hocvan){
                                if(parseInt(el.maTP)===parseInt(data.tinh_tp_hocvan.split(' - ')[0])){
                                    arr5.push(el)
                                }
                            }
                        })
                    }

                    else if(key==='huyen_quan_thuongtru'){
                        // setId_tinh(data[key]?data[key].split(' - ')[0]:'')
                        school.phuong_xa.forEach(el=>{
                            if(data[key]){
                                if(parseInt(el.maQH)===parseInt(data[key].split(' - ')[0])){
                                    arr2.push(el)
                                }
                            }

                            if(huyen_lienhe){
                                if(parseInt(el.maQH)===parseInt(huyen_lienhe.split(' - ')[0])){
                                    arr4.push(el)
                                }
                            }
                        })
                    }

                    // else if(key==='chuongtrinh_nganh_1'){
                    //     // setId_tinh(data[key]?data[key].split(' - ')[0]:'')
                    //     nganh.forEach(el=>{
                    //         if(data[key]){
                    //             if(parseInt(el.id_ctdt)===parseInt(data[key].split(' - ')[0])){
                    //                 nganh_1.push(el)
                    //             }
                    //         }
                    //     })
                    // }

                    // else if(key==='chuongtrinh_nganh_2'){
                    //     // setId_tinh(data[key]?data[key].split(' - ')[0]:'')
                    //     nganh.forEach(el=>{
                    //         if(data[key]){
                    //             if(parseInt(el.id_ctdt)===parseInt(data[key].split(' - ')[0])){
                    //                 nganh_2.push(el)
                    //             }
                    //         }
                    //     })
                    // }
                }
            }
            setHuyenPublic(arr)
            setXaPublic(arr2)
            // setNganh_public_1(nganh_1)
            // setNganh_public_2(nganh_1)

            setHuyenPublic2(arr3)
            setXaPublic2(arr4)
            setHuyenPublic3(arr5)
        }
        handleAutocomplete()
    },[data, tinh_lienhe, huyen_lienhe])

    useEffect(()=>{
        const getDataCTDT = async ()=>{
            try {
                const res = await axios.get(`https://apixettuyen.h.edu.vn/api/bacdaotao?donvi=${school.truong}`)
                setChuongTrinhDaoTao((res.data))
                
            } catch (error) {
                alert(error)
            }
        }
        getDataCTDT()
    },[])

    useEffect(()=>{
        const getDataNganh = async ()=>{
            let id_bac
            chuongTrinhDaoTao.forEach(el=>{
                if(data.chuongtrinh_nganh_1){
                    if(el.ten===data.chuongtrinh_nganh_1){
                        id_bac = el.id
                    }
                    // if(parseInt(el.ma)===parseInt(data.chuongtrinh_nganh_1.split(' - ')[0])){
                    //     id_bac = el.id
                    // }
                }
            })

            try {
                const res = await axios.get(`https://apixettuyen.h.edu.vn/api/nganhxet?bacdaotao=${id_bac}`)
                setNganh_public_1(res.data)
                setNganh_public_2(res.data)
                
            } catch (error) {
                alert(error)
            }
            
        }
        getDataNganh()
    },[data.chuongtrinh_nganh_1])

    const [checkShowNganh, setCheckShowNganh] = useState(false)
    useEffect(()=>{
        let arr=[]
        for (let key in data) {
            if (data.hasOwnProperty(key)){
                if(key==='nganh_1'){
                    if(data[key]){
                        arr.push(data[key])
                    }
                }

                if(key==='nganh_2'){
                    if(data[key]==='' || data[key]===null){
                        setThemNganh(false)
                    }
                }

                if(key==='chuongtrinh_nganh_1'){
                    if(data[key]==='' || data[key]===null){
                        data['nganh_1']=''
                        data['nganh_2']=''
                    }
                }

                if(key){
                    if(data[key]!=='' || data[key]!==null){
                        setHodem(false)
                        setTen(false)
                        setGioitinh(false)
                        setDantoc(false)
                        setTongiao(false)
                        setNgaysinh(false)
                        setNoisinh(false)
                        setDienthoaithisinh(false)
                        setEmail(false)
                        setCccd(false)
                        setNgaycap(false)
                        setNoicap(false)
                        setDienthoaiphuhuynh(false)
                        setTinhTpThuongtru(false)
                        setHuyenQuanThuongtru(false)
                        setXaPhuongThuongtru(false)
                        setDiachiThuongtru(false)
                        setTinhTpHocvan(false)
                        setHuyenTpHocvan(false)
                        setTentruongtrunghocHocvan(false)
                        setChuongtrinhNganh1(false)
                        setNganh1(false)
                        setTotnghiep(false)
                    }
                }

                if(tinh_lienhe!==''||tinh_lienhe!==null){
                    setTinhLienhe2(false)
                }
                if(huyen_lienhe!==''||huyen_lienhe!==null){
                    setHuyenLienhe2(false)
                }
                if(xa_lienhe!==''||xa_lienhe!==null){
                    setXaLienhe2(false)
                }
                if(diachi_lienhe!==''||diachi_lienhe!==null){
                    setDiachiLienhe2(false)
                }
            }
        }
        setCheckShowNganh(arr)
    },[data, tinh_lienhe, huyen_lienhe, xa_lienhe, diachi_lienhe])

    const Validation = ()=>{
        for (let key in data) {
            if (data.hasOwnProperty(key)){
                if(data[key]==='' || data[key]===null){
                    switch (key) {
                        case 'hodem':
                            return alert(`Bạn chưa nhập "Họ đệm"`), setHodem(true)
                            break;
                        case 'ten':
                            return alert(`Bạn chưa nhập "Tên"`), setTen(true)
                            break;
                        case 'gioitinh':
                            return alert(`Bạn chưa chọn "Giới tính"`), setGioitinh(true)
                            break;
                        case 'dantoc':
                            return alert(`Bạn chưa nhập "Dân tộc"`), setDantoc(true)
                            break;
                        case 'tongiao':
                            return alert(`Bạn chưa nhập "Tôn giáo"`), setTongiao(true)
                            break;
                        case 'ngaysinh':
                            return alert(`Bạn chưa chọn "Ngày sinh"`), setNgaysinh(true)
                            break;
                        case 'noisinh':
                            return alert(`Bạn chưa nhập "Nơi sinh"`), setNoisinh(true)
                            break;
                        case 'dienthoaithisinh':
                            return alert(`Bạn chưa nhập "Số điện thoại"`), setDienthoaithisinh(true)
                            break;
                        case 'email':
                            return alert(`Bạn chưa nhập "Email"`), setEmail(true)
                            break;
                        case 'cccd':
                            return alert(`Bạn chưa nhập "CMND/CCCD"`), setCccd(true)
                            break;
                        case 'ngaycap':
                            return alert(`Bạn chưa chọn "Ngày cấp CMND/CCCD"`), setNgaycap(true)
                            break;
                        case 'noicap':
                            return alert(`Bạn chưa nhập "Nơi cấp CMND/CCCD"`), setNoicap(true)
                            break;
                        // case 'dienthoaiphuhuynh':
                        //     return alert(`Bạn chưa nhập "Số điện thoại phụ huynh"`), setDienthoaiphuhuynh(true)
                        //     break;
                        case 'tinh_tp_thuongtru':
                            return alert(`Bạn chưa chọn "Tỉnh/Thành phố thường trú"`), setTinhTpThuongtru(true)
                            break;
                        case 'huyen_quan_thuongtru':
                            return alert(`Bạn chưa chọn "Huyện/Quận thường trú"`), setHuyenQuanThuongtru(true)
                            break;
                        case 'xa_phuong_thuongtru':
                            return alert(`Bạn chưa chọn "Xã/Phường thường trú"`), setXaPhuongThuongtru(true)
                            break;
                        case 'diachi_thuongtru':
                            return alert(`Bạn chưa nhập "Địa chỉ thường trú"`), setDiachiThuongtru(true)
                            break;
                        case 'tinh_tp_hocvan':
                            return alert(`Bạn chưa chọn "Tỉnh/Thành phố trường trung học"`), setTinhTpHocvan(true)
                            break;
                        case 'huyen_quan_hocvan':
                            return alert(`Bạn chưa chọn "Huyện/Quận trường trung học"`), setHuyenTpHocvan(true)
                            break;
                        case 'tentruongtrunghoc_hocvan':
                            return alert(`Bạn chưa chọn "Tên trường trung học"`), setTentruongtrunghocHocvan(true)
                            break;
                        case 'chuongtrinh_nganh_1':
                            return alert(`Bạn chưa chọn "Chương trình đào tạo"`), setChuongtrinhNganh1(true)
                            break;
                        case 'nganh_1':
                            return alert(`Bạn chưa chọn "Ngành dào tạo"`), setNganh1(true)
                            break;
                        case 'totnghiep':
                            return alert(`Bạn chưa xác nhận thông tin về bằng tốt nghiệp.`), setTotnghiep(true)
                            break;

                        // default:
                        //     return true
                    }
                }
            }
        }

        if(tinh_lienhe===''||tinh_lienhe===null){
            return alert(`Bạn chưa chọn "Tỉnh/Thành phố liên hệ"`), setTinhLienhe2(true)
        }
        if(huyen_lienhe===''||huyen_lienhe===null){
            return alert(`Bạn chưa chọn "Xã/Phường liên hệ"`), setHuyenLienhe2(true)
        }
        if(xa_lienhe===''||xa_lienhe===null){
            return alert(`Bạn chưa chọn "Xã/Phường liên hệ"`), setXaLienhe2(true)
        }
        if(diachi_lienhe===''||diachi_lienhe===null){
            return alert(`Bạn chưa nhập "Địa chỉ liên hệ"`), setDiachiLienhe2(true)
        }
        
        return true
    }

    useEffect(()=>{
        if(hodem||ten||gioitinh||dantoc||tongiao||ngaysinh||noisinh||dienthoaithisinh||email||cccd||ngaycap||noicap||dienthoaiphuhuynh){
            if (thong_tin_thi_sinh.current) {
                thong_tin_thi_sinh.current.scrollIntoView({ behavior: 'smooth' });
            }
            setShowForm_thongtin(true)
        }
        else if(tinh_tp_thuongtru||huyen_quan_thuongtru||xa_phuong_thuongtru||diachi_thuongtru||tinh_lienhe2||huyen_lienhe2||xa_lienhe2||diachi_lienhe2) {
            if (thong_tin_lien_lac.current) {
                thong_tin_lien_lac.current.scrollIntoView({ behavior: 'smooth' });
            }
            setShowForm_lienlac(true)
        }
        else if(tinh_tp_hocvan||tentruongtrunghoc_hocvan){
            if (thong_tin_hoc_van.current) {
                thong_tin_hoc_van.current.scrollIntoView({ behavior: 'smooth' });
            }
            setShowForm_hocvan(true)
        }
        else if(chuongtrinh_nganh_1||nganh_1||totnghiep){
            if (thong_tin_dang_ky.current) {
                thong_tin_dang_ky.current.scrollIntoView({ behavior: 'smooth' });
            }
            setShowForm_dangky(true)
        }

    },[hodem,ten,gioitinh,dantoc,tongiao,ngaysinh,noisinh,dienthoaithisinh,email,cccd,ngaycap,noicap,
        dienthoaiphuhuynh,tinh_tp_thuongtru,huyen_quan_thuongtru,xa_phuong_thuongtru,diachi_thuongtru,tinh_lienhe2,
        huyen_lienhe2,xa_lienhe2,diachi_lienhe2,tinh_tp_hocvan,tentruongtrunghoc_hocvan,chuongtrinh_nganh_1,nganh_1,totnghiep])

    const CheckCTDT = ()=>{
        data['nganh_1']=''
        data['nganh_2']=''
    }

    // đối tượng tất cả dữ liệu cần lưu
    const newData = Object.assign({}, data, { tinh_tp_lienlac: tinh_lienhe, huyen_quan_lienlac: huyen_lienhe, xa_phuong_lienlac: xa_lienhe, diachi_lienlac: diachi_lienhe, image_cccd: image_cccd, image_hocba: image_hocba, image_bangcap: image_bangcap, truong: school.truong })
    
    const handleSubmit = async (e)=>{
        e.preventDefault();
        if(Validation()){
            const dataFinal = new FormData();
            for (const key in newData) {
                dataFinal.append(key, newData[key]);
            }

            try {
                const res = await axios.post('https://apixettuyen.h.edu.vn/api/xettuyen', dataFinal, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                })

                if(res) {
                    setCheckpopup(!checkPopup)
                }
                
            } catch (err) {
                alert(err)
            }
        }
    }

    console.log(newData)

  return (
    <div style={{width:'100%', backgroundColor:'#f0f5fd', paddingBottom:'20px', position:'relative'}}>
        <Header isMobile={isMobile} school={school} />

        <div style={{backgroundColor:'#fff', margin:`${isMobile?'20px 10px 0 10px':'20px 30px 0 30px'}`, padding:`${isMobile?'20px 10px':'20px 30px'}`, borderRadius:'10px', boxShadow:'5px 5px 5px #ddd'}}>
            <h1 style={{color:school.color, textAlign:'center'}}>ĐĂNG KÝ XÉT TUYỂN NĂM 2024</h1>
            <p style={{color:'red', fontSize:'12px'}}><i>(*): Thông tin bắt buộc</i></p>

            <div onClick={()=>setShowForm_thongtin(!ShowForm_thongtin)}
                style={{display:'flex', cursor:'pointer', justifyItems:'center', color:'#fff', backgroundColor:school.color, borderRadius:'5px', padding:'0 20px', fontWeight:'bold'}}>
                <p style={{paddingTop:'2px', marginRight:'10px'}}>{!ShowForm_thongtin?<FaChevronRight size={'15px'}/>:<FaChevronDown size={'15px'}/>}</p>
                <p style={{}}>Thông tin thí sinh</p>
            </div>

            <div ref={thong_tin_thi_sinh} style={{display:`${ShowForm_thongtin?'block':'none'}`, border:'1px solid #1360ab', padding:'15px', backgroundColor:'#fff', marginTop:'20px'}}>
                <Grid container spacing={2} sx={{}}>
                    <Grid item xs={12} md={3}>
                        <TextField name='hodem' value={data.hodem} onChange={handleChange} sx={{backgroundColor:`${hodem?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Họ và tên đệm *" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField name='ten' value={data.ten} onChange={handleChange} sx={{backgroundColor:`${ten?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Tên *" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={2} sx={{ width:`100%`, backgroundColor:`${gioitinh?'#FFC1C1':'#f8f8f8'}` }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                options={['Nam', 'Nữ'].map((option) => { return`${option}`})}
                                onChange={handleAutocompleteChange('gioitinh')}
                                renderInput={(params) => <TextField {...params} label="Giới tính *" />}
                            />
                        </Stack>
                        {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Giới tính</InputLabel>
                            <Select sx={{backgroundColor:`${gioitinh?'#FFC1C1':'#f8f8f8'}`, width:'100%'}}
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.gioitinh}
                            label="Giới tính"
                            name='gioitinh'
                            onChange={handleChange}
                            >
                            <MenuItem value={'Nam'}>Nam</MenuItem>
                            <MenuItem value={'Nữ'}>Nữ</MenuItem>
                            </Select>
                        </FormControl> */}
                        {/* <TextField name='gioitinh' value={data.gioitinh} onChange={handleChange} sx={{backgroundColor:`${gioitinh?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Giới tính *" variant="outlined" /> */}
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                        <Stack spacing={2} sx={{ width:`100%`, backgroundColor:`${dantoc?'#FFC1C1':'#f8f8f8'}` }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                options={school.dantoc.map((option) => { return`${option}`})}
                                onChange={handleAutocompleteChange('dantoc')}
                                renderInput={(params) => <TextField {...params} label="Dân tộc *" />}
                            />
                        </Stack>
                        {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Dân tộc</InputLabel>
                            <Select sx={{backgroundColor:`${dantoc?'#FFC1C1':'#f8f8f8'}`, width:'100%'}}
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.dantoc}
                            label="Dân tộc"
                            name='dantoc'
                            onChange={handleChange}
                            >
                            {
                                school.dantoc.map((dantoc,i)=>(
                                    <MenuItem value={dantoc} key={i}>{dantoc}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl> */}
                        {/* <TextField name='dantoc' value={data.dantoc} onChange={handleChange} sx={{backgroundColor:`${dantoc?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Dân tộc *" variant="outlined" /> */}
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                        <Stack spacing={2} sx={{ width:`100%`, backgroundColor:`${tongiao?'#FFC1C1':'#f8f8f8'}` }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                options={school.tongiao.map((option) => { return`${option}`})}
                                onChange={handleAutocompleteChange('tongiao')}
                                renderInput={(params) => <TextField {...params} label="Tôn giáo *" />}
                            />
                        </Stack>
                        {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Tôn giáo</InputLabel>
                            <Select sx={{backgroundColor:`${tongiao?'#FFC1C1':'#f8f8f8'}`, width:'100%'}}
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.tongiao}
                            label="Tôn giáo"
                            name='tongiao'
                            onChange={handleChange}
                            >
                            {
                                school.tongiao.map((tongiao,i)=>(
                                    <MenuItem value={tongiao} key={i}>{tongiao}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl> */}
                        {/* <TextField name='tongiao' value={data.tongiao} onChange={handleChange} sx={{backgroundColor:`${tongiao?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Tôn giáo *" variant="outlined" /> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{marginTop:'10px'}}>
                    <Grid item xs={12} md={3}>
                        <TextField type='date' name='ngaysinh' value={data.ngaysinh} onChange={handleChange} sx={{backgroundColor:`${ngaysinh?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Ngày sinh *" variant="outlined" InputLabelProps={{shrink: true,}} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField name='noisinh' value={data.noisinh} onChange={handleChange} sx={{backgroundColor:`${noisinh?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Nơi sinh *" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField name='dienthoaithisinh' value={data.dienthoaithisinh} onChange={handleChange} sx={{backgroundColor:`${dienthoaithisinh?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Số điện thoại thí sinh *" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField name='email' value={data.email} onChange={handleChange} sx={{backgroundColor:`${email?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Email *" variant="outlined" />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{marginTop:'10px'}}>
                    <Grid item xs={12} md={3}>
                        <TextField name='cccd' value={data.cccd} onChange={handleChange} sx={{backgroundColor:`${cccd?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Số CMND/CCCD *" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField type='date' name='ngaycap' value={data.ngaycap} onChange={handleChange} sx={{backgroundColor:`${ngaycap?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Ngày cấp CMND/CCCD *" variant="outlined" InputLabelProps={{shrink: true,}} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField name='noicap' value={data.noicap} onChange={handleChange} sx={{backgroundColor:`${noicap?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Nơi cấp *" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField name='dienthoaiphuhuynh' value={data.dienthoaiphuhuynh} onChange={handleChange} sx={{backgroundColor:`${dienthoaiphuhuynh?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Số điện thoại phụ huynh" variant="outlined" />
                    </Grid>
                </Grid>
            </div>

            <div onClick={()=>setShowForm_lienlac(!ShowForm_lienlac)}
                style={{display:'flex', cursor:'pointer', justifyItems:'center', color:'#fff', backgroundColor:school.color, borderRadius:'5px', padding:'0 20px', fontWeight:'bold', marginTop:'20px'}}>
                <p style={{paddingTop:'2px', marginRight:'10px'}}>{!ShowForm_lienlac?<FaChevronRight size={'15px'}/>:<FaChevronDown size={'15px'}/>}</p>
                <p style={{}}>Thông tin liên lạc</p>
            </div>

            <div ref={thong_tin_lien_lac} style={{display:`${ShowForm_lienlac?'flex':'none'}`, flexWrap:`${isMobile?'wrap':''}`, justifyContent:'space-between', marginTop:'20px'}}>
                <div style={{width:`${isMobile?'100%':'46%'}`, border:'1px solid #1360ab', padding:'0 20px 20px 20px'}}>
                    <p><b>ĐỊA CHỈ THƯỜNG TRÚ</b></p>
                    <Stack spacing={2} sx={{ width:`${isMobile?'100%':'50%'}`, backgroundColor:`${tinh_tp_thuongtru?'#FFC1C1':'#f8f8f8'}`, marginTop:'58px' }}>
                        <Autocomplete
                            id="free-solo-demo"
                            size='small'
                            freeSolo
                            options={school.tinh.map((option) => { return`${option.id} - ${option.name}`})}
                            onChange={handleAutocompleteChange('tinh_tp_thuongtru')}
                            onInputChange={handleAutocompleteChange('tinh_tp_thuongtru')}
                            renderInput={(params) => <TextField {...params} label="Tỉnh/Thành phố*" />}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width:`${isMobile?'100%':'50%'}`, backgroundColor:`${huyen_quan_thuongtru?'#FFC1C1':'#f8f8f8'}`, marginTop:'20px' }}>
                        <Autocomplete
                            id="free-solo-demo"
                            size='small'
                            freeSolo
                            options={HuyenPublic.map((option) => {
                                return `${option.maQH} - ${option.tenQH}`
                            })}
                            onChange={handleAutocompleteChange('huyen_quan_thuongtru')}
                            onInputChange={handleAutocompleteChange('huyen_quan_thuongtru')}
                            renderInput={(params) => <TextField {...params} label="Huyện/Quận*" />}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width:`${isMobile?'100%':'50%'}`, backgroundColor:`${xa_phuong_thuongtru?'#FFC1C1':'#f8f8f8'}`, marginTop:'20px' }}>
                        <Autocomplete
                            id="free-solo-demo"
                            size='small'
                            freeSolo
                            options={XaPublic.map((option) => {return `${option.maPX} - ${option.tenPX}`})}
                            onChange={handleAutocompleteChange('xa_phuong_thuongtru')}
                            onInputChange={handleAutocompleteChange('xa_phuong_thuongtru')}
                            renderInput={(params) => <TextField {...params} label="Xã/Phường*" />}
                        />
                    </Stack>
                    <TextField name='diachi_thuongtru' value={data.diachi_thuongtru} onChange={handleChange} sx={{backgroundColor:`${diachi_thuongtru?'#FFC1C1':'#f8f8f8'}`, width:'100%', marginTop:'20px'}} id="outlined-basic" size='small' label="Địa chỉ *" variant="outlined" />
                </div>

                <div style={{width:`${isMobile?'100%':'46%'}`, border:'1px solid #1360ab', padding:'0 20px 20px 20px'}}>
                    <p><b>ĐỊA CHỈ LIÊN LẠC</b></p>
                    <FormControlLabel sx={{marginTop:'-10px', marginBottom:'8px'}}
                        control={<Checkbox value={checkLienLac} onChange={()=>setCheckLienLac(!checkLienLac)} />} label="Giống như địa chỉ thường trú" />
                    <Stack spacing={2} sx={{ width: `${isMobile?'100%':'50%'}`, backgroundColor:`${tinh_lienhe2?'#FFC1C1':'#f8f8f8'}` }}>
                        <Autocomplete
                            id="free-solo-demo"
                            size='small'
                            freeSolo
                            options={school.tinh.map((option) => { return`${option.id} - ${option.name}`})}
                            onChange={(event, newValue) => {
                                setTinh(newValue);
                            }}
                            onInputChange={(event, newValue) => {
                                setTinh(newValue);
                            }}
                            value={checkLienLac?data.tinh_tp_thuongtru:''}
                            renderInput={(params) => <TextField {...params} label="Tỉnh/Thành phố*" />}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width:`${isMobile?'100%':'50%'}`, backgroundColor:`${huyen_lienhe2?'#FFC1C1':'#f8f8f8'}`, marginTop:'20px' }}>
                        <Autocomplete
                            id="free-solo-demo"
                            size='small'
                            freeSolo
                            options={HuyenPublic2.map((option) => {{return `${option.maQH} - ${option.tenQH}`}})}
                            onChange={(event, newValue) => {
                                setHuyen(newValue);
                            }}
                            onInputChange={(event, newValue) => {
                                setHuyen(newValue);
                            }}
                            value={checkLienLac?data.huyen_quan_thuongtru:''}
                            renderInput={(params) => <TextField {...params} label="Huyện/Quận*" />}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width:`${isMobile?'100%':'50%'}`, backgroundColor:`${xa_lienhe2?'#FFC1C1':'#f8f8f8'}`, marginTop:'20px' }}>
                        <Autocomplete
                            id="free-solo-demo"
                            size='small'
                            freeSolo
                            options={XaPublic2.map((option) => {return `${option.maPX} - ${option.tenPX}`})}
                            onChange={(event, newValue) => {
                                setXa(newValue);
                            }}
                            onInputChange={(event, newValue) => {
                                setXa(newValue);
                            }}
                            value={checkLienLac?data.xa_phuong_thuongtru:''}
                            renderInput={(params) => <TextField {...params} label="Xã/Phường*" />}
                        />
                    </Stack>
                    <TextField name='diachi_lienlac' value={checkLienLac?data.diachi_thuongtru:diachi_lienhe} onChange={(e)=>setDiachi(e.target.value)} sx={{backgroundColor:`${diachi_lienhe2?'#FFC1C1':'#f8f8f8'}`, width:'100%', marginTop:'20px'}} id="outlined-basic" size='small' label="Địa chỉ *" variant="outlined" />
                </div>
            </div>

            <div onClick={()=>setShowForm_hocvan(!ShowForm_hocvan)}
                style={{display:'flex', cursor:'pointer', justifyItems:'center', color:'#fff', backgroundColor:school.color, borderRadius:'5px', padding:'0 20px', fontWeight:'bold', marginTop:'20px'}}>
                <p style={{paddingTop:'2px', marginRight:'10px'}}>{!ShowForm_hocvan?<FaChevronRight size={'15px'}/>:<FaChevronDown size={'15px'}/>}</p>
                <p style={{}}>Thông tin học vấn</p>
            </div>

            <div ref={thong_tin_hoc_van} style={{display:`${ShowForm_hocvan?'block':'none'}`, border:'1px solid #1360ab', padding:'20px 20px', marginTop:'20px'}}>
                <Grid container spacing={2} sx={{display:`${isMobile?'':''}`, marginTop:'5px'}}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2} sx={{ width:`100%`, backgroundColor:`${tinh_tp_hocvan?'#FFC1C1':'#f8f8f8'}` }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                options={school.tinh.map((option) => { return`${option.id} - ${option.name}`})}
                                onChange={handleAutocompleteChange('tinh_tp_hocvan')}
                                renderInput={(params) => <TextField {...params} label="Tỉnh/Thành phố *" />}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2} sx={{ width:`100%`, backgroundColor:`${tinh_huyen_hocvan?'#FFC1C1':'#f8f8f8'}` }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                options={HuyenPublic3.map((option) => { return`${option.maQH} - ${option.tenQH}`})}
                                onChange={handleAutocompleteChange('huyen_quan_hocvan')}
                                renderInput={(params) => <TextField {...params} label="Huyện/Quận *" />}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{display:`${isMobile?'':''}`, marginTop:'5px'}}>
                    <Grid item xs={12} md={6}>
                        {/* <Stack spacing={2} sx={{ backgroundColor:`${tentruongtrunghoc_hocvan?'#FFC1C1':'#f8f8f8'}` }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                options={dataTinh.map((option) => option)}
                                onChange={handleAutocompleteChange('tentruongtrunghoc_hocvan')}
                                renderInput={(params) => <TextField {...params} label="Tên trường trung học *" />}
                            />
                        </Stack> */}
                        <TextField name='tentruongtrunghoc_hocvan' value={data.tentruongtrunghoc_hocvan} onChange={handleChange} sx={{backgroundColor:`${tentruongtrunghoc_hocvan?'#FFC1C1':'#f8f8f8'}`, width:'100%'}} id="outlined-basic" size='small' label="Tên trường trung học *" variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField name='khac_hocvan' value={data.khac_hocvan} onChange={handleChange} sx={{backgroundColor:'#f8f8f8', width:'100%'}} id="outlined-basic" size='small' label="Khác" variant="outlined" />
                    </Grid>
                </Grid>
            </div>

            <div onClick={()=>setShowForm_dangky(!ShowForm_dangky)}
                style={{display:'flex', cursor:'pointer', justifyItems:'center', color:'#fff', backgroundColor:school.color, borderRadius:'5px', padding:'0 20px', fontWeight:'bold', marginTop:'20px'}}>
                <p style={{paddingTop:'2px', marginRight:'10px'}}>{!ShowForm_dangky?<FaChevronRight size={'15px'}/>:<FaChevronDown size={'15px'}/>}</p>
                <p style={{}}>Thông tin đăng ký</p>
            </div>

            <div ref={thong_tin_dang_ky} style={{display:`${ShowForm_dangky?'block':'none'}`, border:'1px solid #1360ab', padding:'20px 20px', marginTop:'20px'}}>
                <Grid container spacing={2} sx={{display:`${isMobile?'':''}`, marginTop:'5px', marginBottom:'10px'}}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2} sx={{ backgroundColor:`${chuongtrinh_nganh_1?'#FFC1C1':'#f8f8f8'}` }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                options={chuongTrinhDaoTao.map((option) => {return `${option.ten}`})}
                                onChange={handleAutocompleteChange('chuongtrinh_nganh_1')}
                                renderInput={(params) => <TextField {...params} label="Đăng ký chương trình đào tạo *" onClick={CheckCTDT} />}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2} sx={{ backgroundColor:`${nganh_1?'#FFC1C1':'#f8f8f8'}` }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                value={data.nganh_1}
                                options={nganh_public_1.map((option) => {return `${option.ma} - ${option.ten}`})}
                                onChange={handleAutocompleteChange('nganh_1')}
                                renderInput={(params) => <TextField {...params} label="Ngành đào tạo *" />}
                            />
                        </Stack>
                        {
                            checkShowNganh.length!==0 && !themNganh ? 
                            <button onClick={()=>setThemNganh(!themNganh)} style={{backgroundColor:school.color, border:'none', color:'#fff', padding:'0px 10px', display:'flex', justifyItems:'center', fontWeight:'bold', cursor:'pointer', marginTop:'20px', borderRadius:'5px'}}><p><FiPlus size={'16px'} /></p> <p> Thêm ngành đăng ký</p></button>
                            :
                            <></>
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{display:`${isMobile?'':''}`, marginTop:'5px', marginBottom:'20px', display:`${themNganh?'':'none'}`}}>
                    <Grid item xs={12} md={6}>
                        {/* <Stack spacing={2} sx={{ backgroundColor:'#f8f8f8' }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                options={chuongTrinhDaoTao.map((option) => {return `${option.id} - ${option.name}`})}
                                onChange={handleAutocompleteChange('chuongtrinh_nganh_2')}
                                renderInput={(params) => <TextField {...params} label="Đăng ký chương trình đào tạo *" />}
                            />
                        </Stack> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2} sx={{ backgroundColor:'#f8f8f8' }}>
                            <Autocomplete
                                id="free-solo-demo"
                                size='small'
                                freeSolo
                                value={data.nganh_2}
                                options={nganh_public_2.map((option) => {return `${option.ma} - ${option.ten}`})}
                                onChange={handleAutocompleteChange('nganh_2')}
                                renderInput={(params) => <TextField {...params} label="Ngành đào tạo *" />}
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <div style={{marginTop:'30px', backgroundColor:`${totnghiep?'#FFC1C1':''}`}}>
                    <p><b>Vào thời điểm nộp hồ sơ này, Tôi:</b></p>
                    <FormControl>
                        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="totnghiep"
                            onChange={handleChange}
                        >
                            <FormControlLabel value='Đã có bằng Tốt nghiệp' control={<Radio />} label="Đã có bằng Tốt nghiệp." sx={{marginTop:'-10px'}} />
                            <FormControlLabel value='Đã có giấy chứng nhận Tốt nghiệp tạm thời' control={<Radio />} label="Đã có giấy chứng nhận Tốt nghiệp tạm thời." sx={{marginTop:`${isMobile?'0':'-10px'}`}} />
                            <FormControlLabel value='Chưa có giấy chứng nhận Tốt nghiệp tạm thời' control={<Radio />} label="Chưa có giấy chứng nhận Tốt nghiệp tạm thời."sx={{marginTop:`${isMobile?'0':'-10px'}`}} />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>

            <div onClick={()=>setShowForm_yeucau(!ShowForm_yeucau)}
                style={{display:'flex', cursor:'pointer', justifyItems:'center', color:'#fff', backgroundColor:school.color, borderRadius:'5px', padding:'0 20px', fontWeight:'bold', marginTop:'20px'}}>
                <p style={{paddingTop:'2px', marginRight:'10px'}}>{!ShowForm_yeucau?<FaChevronRight size={'15px'}/>:<FaChevronDown size={'15px'}/>}</p>
                <p style={{}}>Giấy tờ yêu cầu</p>
            </div>

            <p style={{display:`${ShowForm_yeucau?'block':'none'}`, color:'red', fontSize:'12px', textAlign:'center'}}><i>Lưu ý: Thí sinh có thể bổ sung sau khi chưa đủ giấy tờ dưới đây.</i></p>
            <p style={{display:`${ShowForm_yeucau?'block':'none'}`, color:'red', fontSize:'12px', marginTop:'-10px', textAlign:'center'}}><i>{`Tải file lên với định dạng PNG, JPG, PDF`}</i></p>

            <div style={{display:`${ShowForm_yeucau?'block':'none'}`, width:`${isMobile?'100%':'56%'}`, border:'1px solid #1360ab', padding:'20px 20px', marginTop:'20px', margin:'0 auto'}}>
                <Grid container spacing={2} sx={{display:`${isMobile?'':'flex'}`, marginTop:'5px', justifyContent:'space-between', marginLeft:'0px'}}>
                    <div style={{display:'flex', justifyItems:'center'}}>
                        <p style={{paddingTop:'6px', marginRight:'10px'}}><FaFileAlt size={'26px'} color='#666'/></p>
                        <div style={{marginTop:'20px'}}>
                            <p style={{fontSize:'13px', margin:'0', color:'#666'}}><b>Số CMND/CCCD</b></p>
                            <p style={{fontSize:'12px', margin:'0', color:'#666'}}><i>Scan/chụp hình cmnd/cccd</i></p>
                        </div>
                    </div>
                    <Grid item xs={12} md={7}>
                        <div style={{border:'1px dashed #ccc', display:'flex'}}>
                            {/* <button style={{color:'red', cursor:'pointer'}}><FiUpload /> Tải tập tin</button> */}
                            <label htmlFor="file-upload-cccd" style={{cursor:'pointer', color:'red', fontSize:'14px', border:'1px solid #bbb', backgroundColor:'#ddd', padding:'10px 10px'}}>
                                <FiUpload /> {`Tải tập tin`}
                            </label>
                            <input id="file-upload-cccd" type="file" accept="image/png, image/jpeg, application/pdf" style={{display: 'none'}} onChange={onFileChangeImage_cccd} />
                            <p style={{fontSize:'13px', marginLeft:'10px'}}>{fileImageName_cccd===''?'chưa đính kèm file':fileImageName_cccd.slice(0,30)+'....'}</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{display:`${isMobile?'':'flex'}`, marginTop:'5px', justifyContent:'space-between', marginLeft:'0px'}}>
                    <div style={{display:'flex', justifyItems:'center'}}>
                        <p style={{paddingTop:'6px', marginRight:'10px'}}><FaFileAlt size={'26px'} color='#666'/></p>
                        <div style={{marginTop:'20px'}}>
                            <p style={{fontSize:'13px', margin:'0', color:'#666'}}><b>Học bạ trung học</b></p>
                            <p style={{fontSize:'12px', margin:'0', color:'#666'}}><i>Scan/chụp hình học bạ</i></p>
                        </div>
                    </div>
                    <Grid item xs={12} md={7}>
                        <div style={{border:'1px dashed #ccc', display:'flex'}}>
                            {/* <button style={{color:'red', cursor:'pointer'}}><FiUpload /> Tải tập tin</button> */}
                            <label htmlFor="file-upload-hocba" style={{cursor:'pointer', color:'red', fontSize:'14px', border:'1px solid #bbb', backgroundColor:'#ddd', padding:'10px 10px'}}>
                                <FiUpload /> {`Tải tập tin`}
                            </label>
                            <input id="file-upload-hocba" type="file" accept="image/png, image/jpeg, application/pdf" style={{display: 'none'}} onChange={onFileChangeImage_hocba} />
                            <p style={{fontSize:'13px', marginLeft:'10px'}}>{fileImageName_hocba===''?'chưa đính kèm file':fileImageName_hocba.slice(0,30)+'....'}</p>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{display:`${isMobile?'':'flex'}`, marginTop:'5px', justifyContent:'space-between', marginLeft:'0px'}}>
                    <div style={{display:'flex', justifyItems:'center'}}>
                        <p style={{paddingTop:'6px', marginRight:'10px'}}><FaFileAlt size={'26px'} color='#666'/></p>
                        <div style={{marginTop:'20px'}}>
                            <p style={{fontSize:'13px', margin:'0', color:'#666'}}><b>Bằng cấp/ CN TNTT</b></p>
                            <p style={{fontSize:'12px', margin:'0', color:'#666'}}><i>Scan/chụp hình bằng cấp/ chứng nhận TNTT</i></p>
                        </div>
                    </div>
                    <Grid item xs={12} md={7}>
                        <div style={{border:'1px dashed #ccc', display:'flex'}}>
                            {/* <button style={{color:'red', cursor:'pointer'}}><FiUpload /> Tải tập tin</button> */}
                            <label htmlFor="file-upload-bangcap" style={{cursor:'pointer', color:'red', fontSize:'14px', border:'1px solid #bbb', backgroundColor:'#ddd', padding:'10px 10px'}}>
                                <FiUpload /> {`Tải tập tin`}
                            </label>
                            <input id="file-upload-bangcap" type="file" accept="image/png, image/jpeg, application/pdf" style={{display: 'none'}} onChange={onFileChangeImage_bangcap} />
                            <p style={{fontSize:'13px', marginLeft:'10px'}}>{fileImageName_bangcap===''?'chưa đính kèm file':fileImageName_bangcap.slice(0,30)+'....'}</p>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <button style={{backgroundColor:school.color, color:'#fff', border:'none', fontWeight:'bold', fontSize:'30px', padding:'10px 25px', borderRadius:'5px', margin:'0 auto', display:'block', marginTop:'20px', cursor:'pointer'}}
                onClick={handleSubmit}>
                ĐĂNG KÝ</button>
        </div>

        {/* popup thông báo */}
        <div style={{ display:`${checkPopup?'block':'none'}`, width:`${isMobile?'90%':'50%'}`, height:`${isMobile?'200px':'50%'}`, position:'fixed', zIndex:'101', top:'50%', left:'50%', transform: 'translate(-50%, -50%)', borderRadius:'10px'}}>
            <button style={{position:'fixed', top:`${isMobile?'5px':'10px'}`, right:`${isMobile?'15px':'20px'}`, cursor:'pointer', backgroundColor:'#444', color:'#fff', border:'none', padding:'5px 10px', borderRadius:'5px'}}
                onClick={()=>setCheckpopup(!checkPopup)}>Đóng</button>
            <img src={Popup} alt='hinh popup thong bao' style={{width:'100%', height:'100%', borderRadius:'10px'}} />
            {/* <p style={{position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -100%)', fontSize:'18px'}}>Đăng ký thành công!</p> */}
        </div>
        <div style={{ display:`${checkPopup?'block':'none'}`, width:'100%', height:'100%', position:'fixed', zIndex:'100', top:'0', left:'0', backgroundColor:'#000', opacity:'0.4'}}></div>
    </div>
  )
}
